.container {
    display: flex;
    margin-top: 1rem;
}

.column {
    width: 50%;
    position: relative;
    padding: 0.5rem;
}

.imageRow {
    display: flex;
    justify-content: space-between;
    height: 220px;
    text-align: center;
    padding: 1rem;
}

.imageRow .rotar {
    max-height: 42px;
    max-width: 42px;
    aspect-ratio: 1/1;
    background: transparent;
}

.imageContainer {
    width: 45%;
    cursor: pointer;
    margin: 0.5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-height: 220px;
}
.imageContainer img {
    max-width: 100%;
    max-height: 220px;
    width: max-content;
    border-radius: 5px;
    margin: 0.5rem;
}

.inputRow {
    display: flex;
    justify-content: space-between;
}

.buttonRow {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.modalRecorte {
    max-height: 80vh;
}

.cropperContainer {
    display: flex;
    width: 80%;
    justify-content: space-between;
    max-height: 60vh;
    align-items: center;
}

.cropper {
    max-width: 400px;
    max-height: 60vh;
    margin: 1rem;
}

.closeButtonContainer {
    display: flex;
    justify-content: center;
}

.button {
    width: 10rem;
    padding: 6px 16px !important;
    height: 40px !important;
    color: #ffffff !important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%);
    border-radius: 2rem !important;
    font-weight: 300 !important;
    margin-bottom: 1rem !important;
    text-transform: capitalize !important;
}

.closeButton {
    background: #bbb !important;
    width: 10rem;
    padding: 6px 16px !important;
    height: 40px !important;
    color: #ffffff !important;
    border-radius: 2rem !important;
    font-weight: 300 !important;
    margin: 0 1rem 1rem !important;
}

.closeButton:hover {
    background: #777 !important;
}

.title {
    display: flex;
    justify-content: center;
    color: #63d1a2;
    margin-top: 0;
    font-weight: lighter;
    font-size: 1.5rem;
}