.container {
    display: flex;
    height: 100%;
}

.column {    
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    height: 80%;
    margin: auto;
    width: 45%; 
}

.columnRenaper {    
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 45%; 
}

.imageRow {
    display: flex;
    justify-content: space-between;
    height: 220px;
    text-align: center;
    padding: 1rem;
}

.form{
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    width: 100%;
}

.inputDiv{
    display: flex;
    justify-content: center;
    width: 100%;
}

.input {
    width: 60%;
}

.checkingData{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -2em;
    width: 20%;
}

.imageRow .rotar {
    aspect-ratio: 1/1;
    background: transparent;
    margin: 1em;
    max-height: 42px;
    max-width: 42px;
}

.imageContainer {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 50%;
}

.imageRotate {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    width: 90%;
}

.error{
    display: block;
    color: red;
    width: 100%;
}


.imageContainer img {
    max-width: 100%;
    max-height: 220px;
    width: max-content;
    border-radius: 5px;
}

.inputRow {
    display: flex;
    justify-content: space-between;
}

.buttonRow {
    text-align: center;
    width: 100%;
}

.modalRecorte {
    max-height: 80vh;
}

.cropperContainer {
    display: flex;
    width: 80%;
    justify-content: space-between;
    max-height: 60vh;
    align-items: center;
}

.cropper {
    max-width: 400px;
    max-height: 60vh;
    margin: 1rem;
}

.closeButtonContainer {
    display: flex;
    justify-content: center;
}

.button {
    width: 10rem;
    padding: 6px 16px !important;
    height: 40px !important;
    color: #ffffff !important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%);
    border-radius: 2rem !important;
    font-weight: 300 !important;
    margin-bottom: 1rem !important;
    text-transform: capitalize !important;
}

.closeButton {
    background: #bbb !important;
    width: 10rem;
    padding: 6px 16px !important;
    height: 40px !important;
    color: #ffffff !important;
    border-radius: 2rem !important;
    font-weight: 300 !important;
    margin: 0 1rem 1rem !important;
}

.closeButton:hover {
    background: #777 !important;
}

.title {
    display: flex;
    justify-content: center;
    color: #63d1a2;
    font-weight: lighter;
    font-size: 1.5rem;
}