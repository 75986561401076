.container{
    display: flex;
}

.column{
    width: 50%;
    position: relative;
    padding: 0.5rem
}

.selfie{
    width: 60%;
    max-height: 650px;
}

.fotoDNI{
    position: absolute;
    width: 168px;
    height: 168px;
    right: 24px;
    cursor: pointer;
    z-index: 100;
}

.dni{
    position: absolute;
    width: 168px;
    height: 168px;
    right: 24px;
    top: 192px;
    cursor: pointer;
}

.inputRow {
    display: flex;
    justify-content: space-between;
}

.buttonRow{
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.button{
    width: 10rem;
    padding: 6px 16px!important;
    height: 40px!important;
    color: #ffffff!important;
    background: linear-gradient(90deg, #63d1a2 0%, #63c3d1 100%);
    text-transform: capitalize!important;
    border-radius: 2rem!important;
    font-weight: 300!important;
    margin-bottom: 1rem!important;
}

.button:hover{
    background: linear-gradient(90deg, #13d5a5 0%, #13c5d5 100%);
}

.closeButton{
    background: #bbb!important;
}

.closeButton:hover{
    background: #777!important;
}

