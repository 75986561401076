.container{
    font-family: 'Roboto', sans-serif;
    width: 80%;
    margin: 0.5rem 4rem;
}

.title {
    display: flex;
    justify-content: center;
    color: #63d1a2;
    font-weight: lighter;
    font-size: 1.5rem;
}
.divider {
    width: 100%;
}
.result{    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.status{
    display: flex;
    align-items: center;
    width: 80%;
}

.icon{
    margin-right: 1em;
}

.registro{
    flex-grow: 1;
    margin: 0.75rem 0;
}

.checkIcon{
    color: #63d1a2;
}
.updateIcon{
    width: 32px;
    height: 32px;
}
.cancelIcon{
    color: #f00
}

.editIcon{
    height: 2em;
    cursor: pointer;
}

.closeButtonContainer {
    display: flex;
    justify-content: center;
}

.button{
    color: inherit;
    width: 274px;
    height: 40px;
    background: #ddd;
    border-radius: 21;
    opacity: 1;
    font-weight: 300;
}